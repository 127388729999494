import React, { PureComponent } from 'react';

class IconArrow2 extends PureComponent {
   render() {
      return (
         <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M5.12135 17L19.5607 2.56066L17.4394 0.439342L3 14.8787V0H0V18.5V20H1.5H20V17H5.12135Z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconArrow2;