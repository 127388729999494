import { apiConstant } from '@apis/constant';
import { fetch } from '@apis/ServiceController';
import { tokenUtil } from '@utils/token';
import _forIn from 'lodash/forIn';
import { adminService } from './adminService';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { FETCH_USERS } from '../constants/actions';

const createUser = async (body) => {
   const formData = new FormData();
   if (body) {
      _forIn(body, (value, key) => {
         formData.append(key, value || '');
      });
   }
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_USER,
      data: formData,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const updateUser = async (username, body) => {
   const formData = new FormData();
   if (body) {
      _forIn(body, (value, key) => {
         formData.append(key, value || '');
      });
   }
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_USER,
      data: formData,
      params: {
         username,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const deleteUser = async (username) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_USER,
      params: {
         username,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const recoverUser = async (username) => {
   return fetch({
      method: 'put',
      url: apiConstant.RECOVER_USER,
      params: {
         username,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const lockUser = async (username) => {
   return fetch({
      method: 'put',
      url: apiConstant.LOCK_USER,
      params: {
         username,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const unlockUser = async (username) => {
   return fetch({
      method: 'put',
      url: apiConstant.UNLOCK_USER,
      params: {
         username,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const resetPassword = async (email) => {
   return fetch({
      method: 'put',
      url: apiConstant.RESET_PASSWORD,
      params: {
         email,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const changePassword = async (body) => {
   return fetch({
      method: 'put',
      url: apiConstant.CHANGE_PASSWORD,
      data: body,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const requestActivation = async (email) => {
   return fetch({
      method: 'put',
      url: apiConstant.REQUEST_ACTIVATION,
      params: {
         email,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
   });
};

const activateUser = async (code) => {
   return fetch({
      method: 'put',
      url: apiConstant.ACTIVATE_USER,
      params: {
         code,
      },
      headers: {},
   });
};

const getUserMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_USER_MATRIX,
      data: body,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
      cancelToken,
   });
};

const getClientCountry = async () => {
   return fetch({
      method: 'get',
      url: 'https://get.geojs.io/v1/ip/country.json',
   });
};

const getSellerDetails = async (username, cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_SELLER_DETAILS,
      params: {
         username,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};
const registerSeller = async (username, body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.REGISTER_SELLER,
      params: {
         username,
      },
      data: body,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};
const removeSellerPermisison = async (usernames, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_SELLER,
      data: {
         usernames,
      },
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const loadUsers = async (body) => {
   return await dispatch(
      Request.post(FETCH_USERS, {
         data: body,
      })
   );
};

const createContact = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_CONTACT,
      data: body,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

export const userService = {
   createUser,
   updateUser,
   deleteUser,
   recoverUser,
   lockUser,
   unlockUser,
   resetPassword,
   changePassword,
   requestActivation,
   activateUser,
   getUserMatrix,
   getClientCountry,
   getSellerDetails,
   registerSeller,
   removeSellerPermisison,
   loadUsers,
   createContact,
};
