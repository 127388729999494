import Input from '@components/Input';
import SEO from '@components/SEO';
import IconArrow2 from '@icons/IconArrow2';
import { InputType } from '@utils/enums/InputType';
import Footer from '@views/default-layout/footer/Footer';
import { Col, Form, Row } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import constant from '@utils/constant';
import { userService } from '@services/userService';
import { message } from '@components/Message';

const fields = [
   {
      name: 'password',
      type: InputType.PASSWORD,
      placeholder: 'New password',
      inputTitle: 'New password',
      rules: [
         {
            required: true,
            message: 'Please input your New password.',
         },
         {
            validator: async (_, value) => {
               const regex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/g;
               if (value && !regex.test(value)) {
                  return Promise.reject(
                     new Error(
                        'Password must have at least one letter and one number, contains at least 8 characters.'
                     )
                  );
               }
               return Promise.resolve();
            },
         },
      ],
   },
   {
      name: 'confirm',
      type: InputType.PASSWORD,
      placeholder: 'Confirm new password',
      inputTitle: 'Confirm new password',
      dependencies: ['password'],
      rules: [
         {
            required: true,
            message: 'Please confirm your New password.',
         },
         ({ getFieldValue }) => ({
            validator(_, value) {
               if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
               }
               return Promise.reject(new Error('Please make sure your passwords match.'));
            },
         }),
      ],
   },
];

const ChangePassword = memo((props) => {
   const code = props.code;
   const [submitting, setSubmitting] = useState(false);

   useEffect(() => {
      if (!code) {
         navigate(constant.ROUTE_HOME);
      }
   }, [code]);

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         console.log(values);

         const repsonse = await userService.changePassword({
            new_password: values.password,
            token: code,
         });
         if (repsonse.isSuccess) {
            message.success('Change password successfully. Please login again.');
            navigate(constant.ROUTE_LOGIN);
            return;
         } else {
            message.error(repsonse.message);
         }
         setSubmitting(false);
      },
      [code]
   );

   return (
      <>
         <SEO title={'Change Password'} />
         <div className="oauth-sub-page activation-page">
            <div className="section-title">
               <span className="section-title-name animate__animated animate__fadeIn">
                  Change password
                  <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                     <IconArrow2 />
                  </span>
               </span>
            </div>
            <Row gutter={30}>
               <Col lg={10} md={12} sm={24} xs={24}>
                  <Form className="submit-form" onFinish={onSubmit}>
                     {fields.map((item) => {
                        return (
                           <Form.Item
                              key={item.name}
                              name={item.name}
                              dependencies={item.dependencies}
                              rules={item.rules || []}
                           >
                              <Input
                                 {...item}
                                 type={item.type}
                                 placeholder={item.placeholder}
                              />
                           </Form.Item>
                        );
                     })}
                     <Form.Item>
                        <button
                           className="common-button"
                           type="primary"
                           style={{ marginTop: '16px', width: '100%' }}
                           disabled={submitting}
                        >
                           {submitting ? 'Submitting...' : 'Submit'}
                        </button>
                     </Form.Item>
                  </Form>
               </Col>
            </Row>
         </div>
         <Footer />
      </>
   );
});

export default ChangePassword;
